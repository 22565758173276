let timelineElements = [
    {
      id: 1,
      title: "Dodałaś mnie na Bereal'u",
      location: "Tylicz",
      buttonText: "Zobacz zdjęcia",
      date: "Październik 2022",
      icon: "heart",
      imagesId: "1u1_sCiLzUliFcKsk-qaxGJZnRS_S_8jf",
      lat: 49.395633191585794,
      lng: 21.02457633637213,
      zoom: 13
    },
    {
      id: 2,
      title: "Pierwsze spotkanie",
      location: "Menelno",
      buttonText: "Zobacz zdjęcia",
      date: "5 grudnia 2022",
      icon: "heart",
      imagesId: "1XnX9J0B1z3M4gCqbEPzL970z-3kK8-MD",
      lat: 49.655518982251394,
      lng: 20.82125433936569,
      zoom: 15
    },
    {
      id: 3,
      title: "Impreza w Las Vegas",
      location: "Barcice",
      buttonText: "Zobacz zdjęcia",
      date: "17 luty 2023",
      icon: "heart",
      imagesId: "1LGvTcB-xWLo6rlli558tbN_u5YTGB9yk",
      lat: 49.526450578038514,
      lng: 20.6584346986099,
      zoom: 13
    },
    {
      id: 4,
      title: "Pierwsza randka",
      location: "Nowy Sącz",
      buttonText: "Zobacz zdjęcia",
      date: "12 marca 2023",
      icon: "heart",
      imagesId: "136grgKLtzz1do2Ei61zXWN5NtEqr0A8t",
      lat: 49.62413560062514,
      lng: 20.706448379696123,
      zoom: 13
    },
    {
      id: 5,
      title: "Spadające gwiazdy",
      location: "Muszyna",
      buttonText: "Zobacz zdjęcia",
      date: "22 kwietnia 2023",
      icon: "heart",
      imagesId: "1a-P058uHsrlXdTR2a2nmjFVVoQL28dQb",
      lat: 49.3475259560473,
      lng: 20.887673610822986,
      zoom: 13
    },
    {
      id: 6,
      title: "Najepszy dzień w życiu",
      location: "Menelno",
      buttonText: "Zobacz zdjęcia",
      date: "9 czerwca 2023",
      icon: "heart",
      imagesId: "1T-olNDybO1B_hkC3zzgV_c1egWKqbvJL",
      lat: 49.654316410785924,
      lng: 20.827638146267514,
      zoom: 13
    },
    {
      id: 7,
      title: "Namioty",
      location: "Tabaszowa",
      buttonText: "Zobacz zdjęcia",
      date: "2-3 sierpnia 2023",
      icon: "heart",
      imagesId: "1M-U42v13NXRs1PQ3rMkmKEY82JaqT3-h",
      lat: 49.75931774111558,
      lng: 20.687257846026444,
      zoom: 13
    },
    {
      id: 8,
      title: "Pierwszy wyjazd do Krakowa",
      location: "Kraków",
      buttonText: "Zobacz zdjęcia",
      date: "16 sierpnia 2023",
      icon: "heart",
      imagesId: "1Kjl178y_q8yYQCRB1qKZeXrgcUod4qlW",
      lat: 50.06481153959426,
      lng: 19.93723600681286,
      zoom: 12
    },
    {
      id: 9,
      title: "Praga",
      location: "Praga",
      buttonText: "Zobacz zdjęcia",
      date: "21-24 sierpnia 2023",
      icon: "heart",
      imagesId: "1-MGsHeedhkFKcuYlb0YqYXS5ayA0zz51",
      lat: 50.07409785873053,
      lng: 14.430595219025184,
      zoom: 12
    },
    {
      id: 10,
      title: "Pierwsza wspólna impreza",
      location: "Menelno",
      buttonText: "Zobacz zdjęcia",
      date: "23 września 2023",
      icon: "heart",
      imagesId: "1uQDUejQ1fadZuU8Yq0ENuCf-KEje6OX7",
      lat: 49.654316410785924,
      lng: 20.827638146267514,
      zoom: 13
    },
    {
      id: 11,
      title: "W sumie to też najlepszy dzień w życiu",
      location: "Tylicz",
      buttonText: "Zobacz zdjęcia",
      date: "28 września 2023",
      icon: "heart",
      imagesId: "1-Py6dgnTSiynrtSdvT1efpwGIldal-bs",
      lat: 49.395633191585794,
      lng: 21.02457633637213,
      zoom: 13
    },
    {
      id: 12,
      title: "Pierwszy wspólny Sylwester",
      location: "Kraków",
      buttonText: "Zobacz zdjęcia",
      date: "31 grudnia 2023",
      icon: "heart",
      imagesId: "1vv8cIVYJ3uv8TZ01B4ZvYM5kyKcdLaY6",
      lat: 50.06481153959426,
      lng: 19.93723600681286,
      zoom: 12
    },
    {
      id: 13,
      title: "Studniówka",
      location: "Rytro",
      buttonText: "Zobacz zdjęcia",
      date: "09 luty 2024",
      icon: "heart",
      imagesId: "1zHOsZU1gDUJpkpRFclwUXL-BC2GYPVfP",
      lat: 49.490726508667585,
      lng: 20.68285232559744,
      zoom: 13
    },
    {
      id: 14,
      title: "Pierwsze Walentynki razem",
      location: "Tylicz",
      buttonText: "Zobacz zdjęcia",
      date: "14 luty 2024",
      icon: "heart",
      imagesId: "1aRkya5ErpJuSQj-DsbyXK5aWquHbze1t",
      lat: 49.395633191585794,
      lng: 21.02457633637213,
      zoom: 13
    },
    {
      id: 15,
      title: "Pierwszy rok razem",
      location: "Hawaje",
      buttonText: "Zobacz zdjęcia",
      date: "17 luty 2024",
      icon: "heart",
      imagesId: "16RtHvfKEi9rfTKOSsItWnP0EkvJYztla",
      lat: 21.310051525820874,
      lng: -157.86089394886287,
      zoom: 13
    },
  ];
  
  export default timelineElements;